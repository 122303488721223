import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getToken, removeToken } from '@/plugins/cookie/auth'
import { Toast } from 'vant'

interface IResponse {
  code: number
  data: any
  error: string
}

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 0,
})

http.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  if (getToken()) {
    config.headers = {
      Authorization: `Bearer ${getToken()}`,
    }
  }
  const { method } = config
  if (method) {
    const requestMethod = method.toLowerCase()
    if (requestMethod === 'get' || requestMethod === 'delete') {
      config.params = Object.assign(config.params || {})
    }
    if (requestMethod === 'post' || requestMethod === 'put') {
      config.data = Object.assign(config.data || {})
    }
  }
  return config
})

http.interceptors.response.use(
  (response): AxiosResponse | IResponse => {
    const { data } = response
    return !data.code ? data.data : data
  },
  (error): Promise<never> => {
    if (error && error.response) {
      resHandle(error.response)
    }
    return Promise.reject(error)
  },
)

function resHandle(response: AxiosResponse) {
  if (response.status === 401) {
    // 401 token过期
    removeToken()
    window.location.replace(`/#/login?backUrl=${window.location.href}`)
    return Promise.reject(response)
  }
  if (response.data.code) {
    // token解析错误
    if (response.data.code === 500) {
      removeToken()
      window.location.replace(`/#/login?backUrl=${window.location.href}`)
      return Promise.reject(response)
    }
    // token无效
    if (response.data.code === 10000) {
      window.location.replace(`/#/login?backUrl=${window.location.href}`)
      return Promise.reject(response)
    } else if (response.data.code === 10002) {
      // token过期
      window.location.replace(`/#/login?backUrl=${window.location.href}`)
      return Promise.reject(response)
    } else if (response.data.code === 10006) {
      // 没绑定手机号
      window.location.replace(`/#/bind?backUrl=${window.location.href}`)
      return Promise.reject(response)
    } else if (response.data.error) {
      Toast(response.data.error)
      return Promise.reject(response)
    } else {
      Toast('请求错误')
      return Promise.reject(response)
    }
  }
}

export default http
